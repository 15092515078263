<template>
  <div class="new_container prohibit-copy" oncontextmenu="return false;" style="background: #fff;height: 100%">
    <nav-header :title="title" fixed/>
    <div class="body "
         style="background: #fff"
         v-if="Object.keys(newDetail).length>0"
    >
      <div class="max-width">
        <div v-html="newDetail.info"></div>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from '@/components/top/NavHeader'
import http from "@/api/http";

export default {
  name: "SpecialistDetails",
  data() {
    return {
      title: '专家详情',
      newDetail: {}, //详情内容
    }
  },
  components: {
    NavHeader
  },
  mounted() {
    if (this.$route.query.id) {
      this.getNewDetail(this.$route.query.id)
    }
  },
  methods: {
    getNewDetail(paramsId) {
      http.get(`/gzhphp/zhuan_jia_ku/getInfo?id=${paramsId}`).then(res => {
        if (res.data) {
          this.newDetail = res.data;
        }
      })
    }
  }

}
</script>

<style scoped lang="less">
@import "../../../assets/da-ting.less";

.new_container {
  background-color: white;
}

.body {
  padding: 10px;
}

</style>
